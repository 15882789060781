﻿/// <reference path="../../entries/main">

@mixin link-helper($color: $blue, $contrast: $blue-dark) {
    color: $color;

    svg {
        fill: $color;
    }

    &:hover, &:active, &:focus {
        color: $contrast;

        svg {
            fill: $contrast;
        }
    }
}
