﻿/// <reference path="../../../../../../../../entries/main">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&family=Space+Grotesk&display=swap');

@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=1138a18e-c013-4917-bede-27d3abe0d667");

@font-face {
    font-family: "Helvetica Neue LT W05_55 Roman";
    src: url("../../../fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot");
    src: url("../../../fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix") format("embedded-opentype"), url("../../../fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"), url("../../../fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff"), url("../../../fonts/8f4a1705-214a-4dd0-80b8-72252c37e688.ttf") format("truetype");
}

@font-face {
    font-family: "Helvetica Neue LT W05_56 Italic";
    src: url("../../../fonts/c708845d-fe72-4432-9276-47c4f91aec1c.eot");
    src: url("../../../fonts/c708845d-fe72-4432-9276-47c4f91aec1c.eot?#iefix") format("embedded-opentype"), url("../../../fonts/4bd56f95-e7ab-4a32-91fd-b8704cbd38bc.woff2") format("woff2"), url("../../../fonts/4fe1c328-1f21-434a-8f0d-5e0cf6c70dfb.woff") format("woff"), url("../../../fonts/c07fd9e2-5e0b-4297-9229-af0c8e331d94.ttf") format("truetype");
}

@font-face {
    font-family: "Helvetica Neue LT W05_65 Medium";
    src: url("../../../fonts/5640036d-d549-4f16-b31e-87da78d9e832.eot");
    src: url("../../../fonts/5640036d-d549-4f16-b31e-87da78d9e832.eot?#iefix") format("embedded-opentype"), url("../../../fonts/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"), url("../../../fonts/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff"), url("../../../fonts/14d824a2-2a5c-4692-8e30-74f6e177b675.ttf") format("truetype");
}

@font-face {
    font-family: "Helvetica Neue LT W05_75 Bold";
    src: url("../../../fonts/4c21ab66-1566-4a85-b310-fbc649985f88.eot");
    src: url("../../../fonts/4c21ab66-1566-4a85-b310-fbc649985f88.eot?#iefix") format("embedded-opentype"), url("../../../fonts/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2") format("woff2"), url("../../../fonts/7b415a05-784a-4a4c-8c94-67e9288312f5.woff") format("woff"), url("../../../fonts/f07c25ed-2d61-4e44-99ab-a0bc3ec67662.ttf") format("truetype");
}
