﻿// Basic colors
$white: #ffffff;
$black: #000000;
$green: #038A3F;
$yellow: #EDB529;
$green-light: #c8e6c9;
$green-dark: #02612C;
$blue: #006FD4;
$red: #AD3333;
$red-medium: mix($white, $red, 10%);
$red-light: #f8d7da;
$gray-lighter: mix($black, $white, 8%);
$gray-light: #F5F5F5;
$gray-dark: mix($black, $white, 72%);
$gray-medium: #D8D8D8;
$gray-neutral: mix($black, $white, 30%);
$gray: #D9D9D9;
$yellow-light: #fff9c4;
$orange: #f57f17;
$blue-light: #bbdefb;
$blue-dark: #062F55;
$shadow: 0.16rem 0.16rem 0.6rem rgba($black, 0.3);

// Common color config
$default-text: #303030;
$default-secondary: $gray-dark;
$default-focus-ring: $blue;

$ui-invalid: $red;
$ui-valid: $green-light;

// Button config for Primary and Secondary styles, declared so it's easy to merge
$default-button-config: (
        off: ($green, $white),
        on: ($green-dark, $white),
        focus: ($green-dark, $white, outline),
        disabled: ($gray-medium, $white)
    );

$default-button-secondary-config: (
        off: ($blue, $white),
        on: ($blue-dark, $white),
        focus: ($blue-dark, $white, outline),
        disabled: ($white, $gray-medium, outline)
    );
$default-button-secondary-inv-config: (
        off: (transparent, $white, outline),
        on: ($blue, $white),
        focus: ($white, $blue, outline),
        disabled: ($white, $gray-medium, outline)
    );

$default-button-tertiary-config: (
        off: (transparent, $blue, outline),
        on: ($blue, $white, outline),
        focus: (transparent, $blue, outline),
        disabled: ($white, $gray-medium, outline)
    );