﻿/// <reference path="../../entries/main">



// ------------- HEADER - STRUCTURE ------------- //
header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px; // overrides for interior pages
    button {
        font-size: 14px; //overrides for interior pages
    }
}


.#{$prefix}main-header {
    display: flex;
    justify-content: space-between;
    padding-top: $gap;
    padding-bottom: $gap;
    align-items: center;
    @extend %standard-padding-h;

    &-logo {
        width: 200px;
    }

    &-utility {
        &-list {
            display: flex;
            align-items: center;


            &-item {
                border-left: 1px solid $gray-dark;
                position: relative;

                &:first-child {
                    border-left: none;
                }

                &:hover, &:focus-within {
                    & > .#{$prefix}main-header-utility-child {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                &-link {
                    padding: 0 $space !important;
                    display: block;

                    &:focus:not(:focus-visible) {
                        box-shadow: none;
                    }
                }
            }
        }

        &-signin {
            @extend %btn-primary;
            background-image: none !important;
            margin-left: $gap-sm;
            font-size: 14px; //overrides for interior pages
        }
    }
}

.#{$prefix}main-header-utility-child {
    background: $white;
    position: absolute;
    box-shadow: $shadow;
    min-width: 16rem;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    top: 100%;
    border-top: 4px solid $blue-dark;


    &-title {
        background: $blue-dark;
        color: $white;
        padding: $space/2 $gap-md;
    }

    &-inner {
        padding: $gap-sm $gap-md $gap-md $gap-md;

        & > ul {

            li + li {
                margin-top: $space;
            }
        }
    }

    &-see-all {
        margin-top: $space;
    }
}
// ------------- MAIN NAV - STRUCTURE ------------- //
.#{$prefix}nav {
    position: relative;

    &-inner {
        @extend %standard-padding-h;
        display: flex;
        justify-content: space-between;
    }

    &-main {
        display: flex;

        &-list {
            display: flex;
            grid-gap: $gap;

            @include media(large) {
                grid-gap: $gap-sm;
            }

            &-item {
                display: flex;
                align-items: center;

                &:hover {
                    .#{$prefix}nav-main-list-item-link {
                        border-color: $white;
                    }
                }

                &-link {
                    @include link-helper($white, $white);
                    display: flex;
                    align-items: center;
                    border-bottom: 2px solid transparent;
                    background-image: none !important;
                    padding-right: 0 !important;

                    svg {
                        width: 1rem;
                        height: 1rem;
                        margin-right: $space;
                    }

                    &:focus:not(:focus-visible) {
                        box-shadow: none;
                    }
                }
            }
        }
    }

    &-secondary {
        padding-top: .75rem;
        padding-bottom: .75rem;
        display: flex;

        &-list {
            display: flex;

            &-item {
                margin-left: $gap-sm;

                &:focus-within {
                    & > .#{$prefix}nav-meganav-panel {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                &-trigger {
                    font-size: 14px; //override
                    @extend %btn;
                    @include button-helper(map-merge($default-button-secondary-config, ( on: ($white, $blue) )));
                    padding-top: $sliver;
                    padding-bottom: $sliver;
                }
            }
        }
    }

    &-search {
        flex-grow: 1;
        display: flex;

        .MuiAutocomplete-root {
            display: flex;
            flex-direction: row-reverse;
            border: 1px solid #303030;
            width: 320px;

            @include media(large) {
                width: 200px;
            }
        }

        .search-bar {
            margin: 0;

            &-container {
                display: flex;
                flex-direction: row-reverse;

                img {
                    height: 100%;
                }
            }
        }

        .adage-header-utility-search {
            form, &-bar {
                display: flex;
                position: relative;
                flex-grow: 1;

                &-button {
                    @extend %btn;
                    @include button-helper(map-merge($default-button-secondary-config, ( on: ($white, $blue) )));
                    border-bottom-right-radius: 0px;
                    border-top-right-radius: 0px;
                    padding-top: $sliver;
                    padding-bottom: $sliver;


                    & > .svg-icon {
                        margin: 0;
                        height: 1rem;
                        width: 1rem;
                    }
                }

                &-clear {
                    position: absolute;
                    top: 50%; /* 3 */
                    transform: translate(0, -50%);
                    right: .25rem;

                    svg {
                        width: .75rem;
                        height: .75rem;
                    }
                }



                .MuiAutocomplete-input, input[type=search] {
                    padding: 5px;
                    margin: 0;
                    /*color: $neutral-1;*/
                    background-color: #fff;
                    font-size: 14px;
                    border: 0;
                    line-height: 1.25;
                    flex-grow: 1;
                    -webkit-appearance: none;
                    border-bottom-left-radius: 0px;
                    border-top-left-radius: 0px;
                    border-bottom-right-radius: 3px;
                    border-top-right-radius: 3px;

                    &:active {
                        /*  color: $primary-text;*/
                    }
                }
            }
            /* WebKit browsers */
            input:focus::-webkit-input-placeholder {
                color: transparent;
            }
            /* Mozilla Firefox 4 to 18 */
            input:focus:-moz-placeholder {
                color: transparent;
            }
            /* Mozilla Firefox 19+ */
            input:focus::-moz-placeholder {
                color: transparent;
            }
            /* Internet Explorer 10+ */
            input:focus:-ms-input-placeholder {
                color: transparent;
            }
        }
    }
}

.#{$prefix}nav {
    background-color: $blue-dark;
}
// ------------- MAIN PAGE SELECT - STRUCTURE ------------- //

.#{$prefix}nav {
    &-page-select {
        margin-left: $gap-sm;

        &-trigger {
            @extend %btn;
            @include button-helper(map-merge($default-button-secondary-config, ( on: ($white, $blue) )));
            border-radius: 0px;
            padding: $space;



            &[aria-expanded="true"] {
                background: $white;
                color: $blue;

                svg {
                    fill: $blue;
                }
            }
        }

        &-panel {
            display: none;

            &[aria-hidden="false"] {
                display: block;
            }
        }
    }
}

.#{$prefix}nav {
    &-meganav {
        &-panel {
            opacity: 0;
            visibility: hidden;
        }

        &-item {
            &:hover, &.focused:focus-within {
                
                & > .#{$prefix}nav-meganav-panel {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}


.#{$prefix}nav-meganav {
    background: $white;
    position: absolute;
    right: 0;
    left: 0;
    box-shadow: $shadow;
    top: 100%;
    z-index: 100;
    transition: $transition;

    h3 {
        color: $default-text;
        font-size: 20px; // interior override
    }

    &-inner {
        /* padding-top: $gap;
        padding-bottom: $gap-lg;*/
        display: flex;

        &-tabless {
            justify-content: space-between;
        }
    }

    &-title {
        margin-bottom: $gap-sm;
        font-size: 24px // interior overrides
    }

    &-description {
        width: 25%;
        flex: 0 0 auto;
        padding-right: $gap;
        padding-top: $gap;
        padding-bottom: $gap-lg;

        @include responsive-context($standard-padding-map) {
            padding-left: $responsive-variable;
        }
    }

    &-main {
        flex: 1 1 auto;
        display: flex;
        /*  display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: $gap-sm;
        flex: 1 1 auto;*/
        &-links {
            background: $gray-light;
            padding-top: $gap;
            padding-bottom: $gap-lg;
            padding-left: $gap-lg;

            @include responsive-context($standard-padding-map) {
                padding-right: $responsive-variable;
            }

            &-tabless {
                width: 50%;
            }
        }
    }
}
// ------------- MAIN NAV CONTAINER - STRUCTURE ------------- //
.#{$prefix}meganav {
    &-tabs {
        &-container {
            display: flex;
            flex: 1 1 auto;
        }

        &-list {
            width: calc(100% / 3);
            padding-top: $gap;
            padding-bottom: $gap-lg;
            padding-left: $gap-lg;

            &-tab {
                @extend %un-button;
                padding: $space $gap;
                border-left: 4px solid transparent;
                width: 100%;
                background: white;
                font-size: 14px; //overrides for interior pages

                &:hover, &-selected {
                    border-color: $blue;
                    background-color: $gray-light !important; // important to fix the hover bug on interior pages
                    color: $default-text;
                    font-family: $heading-font;
                }

                &:focus {
                    color: $default-text;

                    &:not(:focus-visible) {
                        box-shadow: none;
                    }
                }
            }
        }

        &-panel {
        }

        &-panels-container {
            width: calc((100% / 3) * 2);
        }
    }

    &-panel-link-list {
        &-title {
            margin-bottom: $space;
            margin-top: 0;
            font-size: 20px /// interior override
        }

        &-item {
            margin-bottom: $space;

            a {
                &[href^="http://"], &[href^="https://"] {
                    &[target=_blank] {
                        background-image: url('~/Static/img/external.svg');
                        padding-right: 1.5rem;
                        background-size: 1rem;
                        background-repeat: no-repeat;
                        background-position: right;
                    }
                }

                &:hover, &:focus {
                    text-decoration: underline;
                }
            }
        }

        &-columns {
            column-count: 2;
            column-rule: 1px solid $gray;
            column-gap: $gap-lg;
        }
    }
}
// ------------- Secondary Meganav styles ------------- //
.#{$prefix}meganav-secondary {
    @extend %standard-padding-h;

    &-description {
        width: 25%;
        flex: 0 0 auto;
        padding-right: $gap;
        margin-right: $gap;
        border-right: 1px solid $gray-medium;

        &-title {
            margin-bottom: $gap-sm;
            margin-top: 0;
            font-size: 24px /// interior override
        }
    }

    &-panel-link-list {
        &-title {
            margin-top: 0;
            margin-bottom: $space;
        }

        &-item {
            a {
                &[href^="http://"], &[href^="https://"] {
                    &[target=_blank] {
                        background-image: url('~/Static/img/external.svg');
                        padding-right: 1.5rem;
                        background-size: 1rem;
                        background-repeat: no-repeat;
                        background-position: right;
                    }
                }

                &:hover, &:focus {
                    text-decoration: underline;
                }
            }
        }
    }

    &-inner {
        padding-top: $gap;
        padding-bottom: $gap-lg;
        display: flex;
        max-width: 75rem;
    }

    &-main {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: $gap-sm;
        flex: 1 1 auto;
    }
}
