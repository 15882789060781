﻿/// <reference path="../../entries/main">

html {
    min-width: 70rem;
    }
body {
    display: flex;
    flex-direction: column;
}

header {
    flex: 0 0 auto;
}

footer {
    flex: 0 0 auto;
}

main {
    flex: 1 0 auto;

    & > *:first-child {
        @include responsive-context($grid-spacing-map) {
            margin-top: $responsive-variable;
        }
    }

    // Safari fix because it puts focus here when buttons inside are clicked, but the <main> is not interactive and does not need an indicator
    &:focus {
        outline: none;
    }

    // Avoid margin collapse for themed sections
    & > [class*="theme"] {
        padding-top: 0.1px;
        padding-bottom: 0.1px;
    }
}

.skip-link {
    position: absolute;
    padding: $space;
    z-index: 1000;
    top: 0;
    left: 0;
    transform: translateY(-100%);

    &:focus {
        transform: none;
    }
}

.#{$prefix}page {
    &,
    &-section,
    &-header {
        @extend %standard-padding-h;
        @extend %standard-margin-v;
        max-width: $max-width;
        margin-left: auto;
        margin-right: auto;
    }
}

.svg-sprite {
    display: none;
}

.skip-link {
    background: $gray-light;
}

.#{$prefix}page {
    &,
    &-section,
    &-header {
        &-gray {
            background: $gray-light;
            padding-top: $gap;
            padding-bottom: $gap;
        }
    }
}

// ace notice
.#{$prefix}notice {
    background: $gray-light;
    padding: $gap-sm;
}