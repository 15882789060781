/// <reference path="../../entries/main">

body {
    &.body-redesign {
        @extend %body;
    }
}

.large, .paragraph-large {
    @extend %large;
}

.small, paragraph-small {
    @extend %small;
}

.accent {
    @extend %accent;
}

a,
.hyperlink {
    @include link-helper();
    text-decoration: none;
}

b,
strong,
.bold {
    font-weight: normal;
    font-family: $heading-font;
}

.medium {
    font-weight: $font-weight-normal;
}

em,
i,
.italic {
    font-style: italic;
}

.align {
    &-right {
        text-align: right;
    }

    &-center {
        text-align: center;
    }

    &-left {
        text-align: left;
    }
}

.header + * {
    @extend %text-top-margin;
}

%light,
.light {
    color: $gray-dark;
}
