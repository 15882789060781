﻿/// <reference path="../../entries/main">

@function generate-background-icon($color: $default-text, $type: 'invalid-icon') {
    $_url-color: currentColor;

    @if $color != currentColor {
        @if str-slice(#{$color}, 1, 1) == '#' {
            $_url-color: '%23' + str-slice(#{$color}, 2, -1);
        }

        @else {
            $_url-color: $color;
        }
    }

    @if $type == 'eye-hidden' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='1 1 22 22'%3E%3Cpath fill='#{$_url-color}' d='M3.4,2.5L2,3.9l2.7,2.7c-1.6,1.3-2.9,3-3.7,5C2.7,15.9,7,19,12,19c1.5,0,3-0.3,4.3-0.8l3.4,3.4l1.4-1.4L3.4,2.5z M9.5,11.4 C9.5,11.4,9.5,11.4,9.5,11.4l2.6,2.5c0,0-0.1,0-0.1,0C10.6,14,9.5,12.9,9.5,11.4C9.5,11.5,9.5,11.5,9.5,11.4z M12,17 c-3.8,0-7.2-2.1-8.8-5.5C3.9,10.1,4.9,8.9,6.1,8l1.8,1.8c-0.2,0.6-0.4,1.2-0.4,1.8c0,2.5,2,4.5,4.5,4.5c0.6,0,1.2-0.1,1.8-0.4l1,1 C13.9,16.9,13,17,12,17z M20.8,11.5C19.2,8.1,15.8,6,12,6c-0.7,0-1.3,0.1-2,0.2L8.4,4.6C9.5,4.2,10.7,4,12,4c5,0,9.3,3.1,11,7.5 c-0.7,1.8-1.8,3.3-3.2,4.5l-1.4-1.4C19.4,13.8,20.2,12.7,20.8,11.5z M13,9.2l-2.1-2.1C11.3,7.1,11.6,7,12,7c2.5,0,4.5,2,4.5,4.5 c0,0.4-0.1,0.7-0.1,1.1l-2.1-2.1C14,9.9,13.6,9.5,13,9.2z'/%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'eye-shown' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='1 1 22 22'%3E%3Cpath fill='#{$_url-color}' d='M23,11.5C21.3,7.1,17,4,12,4S2.7,7.1,1,11.5c0,0,0,0,0,0c0,0,0,0,0,0C2.7,15.9,7,19,12,19S21.3,15.9,23,11.5 C23,11.5,23,11.5,23,11.5C23,11.5,23,11.5,23,11.5z M12,17c-3.8,0-7.2-2.1-8.8-5.5h0c0,0,0,0,0,0c0,0,0,0,0,0h0C4.8,8.1,8.2,6,12,6 s7.2,2.1,8.8,5.5h0c0,0,0,0,0,0c0,0,0,0,0,0h0C19.2,14.9,15.8,17,12,17z M16.5,11.5C16.5,9,14.5,7,12,7s-4.5,2-4.5,4.5c0,0,0,0,0,0 c0,0,0,0,0,0c0,2.5,2,4.5,4.5,4.5S16.5,14,16.5,11.5C16.5,11.5,16.5,11.5,16.5,11.5C16.5,11.5,16.5,11.5,16.5,11.5z M12,14 c-1.4,0-2.5-1.1-2.5-2.5h0c0,0,0,0,0,0c0,0,0,0,0,0h0C9.5,10.1,10.6,9,12,9s2.5,1.1,2.5,2.5h0c0,0,0,0,0,0c0,0,0,0,0,0h0 C14.5,12.9,13.4,14,12,14z'/%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'select-dropdown' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 43' height='43' width='43'%3E%3Cpolygon fill='#{$_url-color}' points='26.5,19 21.5,24 16.5,19 '/%3E%3C/svg%3E");
    }

    @elseif $type == 'invalid-icon' {
        @return url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$_url-color}' d='M10 20.5C4.4858 20.5 0 16.0146 0 10.5C0 4.9858 4.4858 0.5 10 0.5C15.5142 0.5 20 4.9858 20 10.5C20 16.0146 15.5142 20.5 10 20.5ZM10 2.5C5.5889 2.5 2 6.0889 2 10.5C2 14.9111 5.5889 18.5 10 18.5C14.4111 18.5 18 14.9111 18 10.5C18 6.0889 14.4111 2.5 10 2.5ZM9 4.5V11.5H11V4.5H9ZM11.25 14.5C11.25 15.1904 10.6904 15.75 10 15.75C9.30964 15.75 8.75 15.1904 8.75 14.5C8.75 13.8096 9.30964 13.25 10 13.25C10.6904 13.25 11.25 13.8096 11.25 14.5Z'/%3E%3C/svg%3E%0A");
    }

    @elseif $type == 'valid-icon' {
        @return url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' height='16' width='16' style='enable-background:new 0 0 16 16;' xml:space='preserve'%3E%3Cpath fill='#{$_url-color}' d='M8,1.3c-3.7,0-6.7,3-6.7,6.7s3,6.7,6.7,6.7c3.7,0,6.7-3,6.7-6.7S11.7,1.3,8,1.3z M11.7,6.5l-4.9,4.9 c-0.1,0.1-0.3,0.1-0.4,0L4.2,9.1C4.1,9,4.1,8.9,4.2,8.8l0.6-0.6c0.1-0.1,0.3-0.1,0.4,0l1.5,1.5l4.2-4.2c0.1-0.1,0.3-0.1,0.4,0 l0.6,0.6C11.8,6.2,11.8,6.4,11.7,6.5z'/%3E%3C/svg%3E");
    }
}

@mixin icon-eye-hidden($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'eye-hidden');
}

@mixin icon-eye-shown($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'eye-shown');
}

@mixin select-dropdown($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'select-dropdown');
}

@mixin icon-invalid($_bgcolor) {
    background-image: generate-background-icon($_bgcolor, 'invalid-icon');
}