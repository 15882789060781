﻿/// <reference path="../../entries/main">

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes scrollSlideUp {
    0% {
        -webkit-transform: translateY(70px);
        transform: translateY(70px);
        opacity: 0;
        visibility: visible;
    }

    60% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
}

.js-fade-in-toggle {
    visibility: hidden;
    will-change: opacity;
    transform: translateY(70px);
}

.fade-in-load {
    @include media-min(tablet) {
        animation: scrollSlideUp $scroll-transition;
    }
}

.fade-in-scroll {
    @include media-min(tablet) {
        animation: scrollSlideUp $scroll-transition*2;
    }
}



.epi-editmode  {
    .js-fade-in-toggle,
    .fade-in-load,
    .fade-in-scroll {
        visibility: visible;
    }
}