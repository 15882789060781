﻿// ------------- FONTS ------------- //
$body-font: 'Helvetica Neue LT W05_55 Roman', Arial, sans-serif !default;
/*$heading-font:  Arial, sans-serif;
*/
$heading-font: 'Helvetica Neue LT W05_65 Medium', Arial, sans-serif !default;

$accent-font: Arial, sans-serif;

// ------------- FONT WEIGHTS ------------- //
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

// ------------- LINE HEIGHTS ------------- //
$heading-line-height: 1.25;
$body-line-height: 1.75;

// Miscellaneous
$form-field-font-size: 1rem;
$form-field-line-height: $body-line-height;
$paragraph-spacing: 1em;