﻿/// <reference path="../../entries/main">

// ------------- FOOTER - STRUCTURE ------------- //
footer {
    border-top: 1px solid transparent;


    .footer-inner {
        max-width: $max-width;
        margin-left: auto;
        margin-right: auto;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    a {
        @include link-helper($white, $white)
    }
}
.#{$prefix}footer {
    @extend %standard-padding-h;
    padding-top: $gap-sm;
    padding-bottom: $gap;
    display: flex;
    justify-content: space-between;

    &-utility {
        border-bottom: 1px solid $white;
        padding-bottom: $space;
        margin-bottom: $space;

       

        &-list {
            display: flex;

            &-item {
                & + & {
                    margin-left: $space;
                    padding-left: $space;
                    border-left: 1px solid $white;
                }
            }
        }
    }

    &-social-media {
        display: flex;
        justify-content: space-between;

        &-list {
            display: flex;

            &-item {

                & + & {
                    margin-left: $gap-md;
                }

                a {
                    display: flex;
                    align-items: center;

                    img {
                        height: 1.125rem;
                        width: 1.125rem;
                        margin-right: $space;
                    }
                }
            }
        }
    }
}
// ------------- FOOTER - SKIN ------------- //
footer {
    background: $blue-dark;
    color: $white;
   
}



/// <reference path="../../main">

.adage-footer {
    min-height: 165px;
    background: #0a4181;
    height: 100%;
    display: flex;
    font-family: $body-font;
}

.adage-footer-inner {
    width: remToPx($max-width);
    margin: 0 auto;
    display: flex;
    padding: 10px 0;
}

.adage-footer-copyright {
    width: 330px;
    margin-top: remToPx(1rem);


}

.adage-footer-utility {
    margin-top: remToPx(1rem);

    p {
        margin: 0;
    }

    &-menu {
        display: flex;
        width: remToPx(45.1rem);
        padding: 0;
        margin: 0;
        align-items: center;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #fff;
        list-style: none;

        li {
            padding: 0;
            margin: 0;
     

            a {
                display: block;
                text-decoration: none;
                color: #fff;
                font-weight: $font-weight-normal;
            }

            + li {
                margin-left: $space;
                padding-left: $space;
                border-left: 1px solid #fff;
            }
        }
    }

    h5 {
        display: inline;
        color: $white;
        vertical-align: super;
        font-family: $body-font;
        font-weight: $font-weight-normal;
    }
}

.adage-footer-utility-social {
    display: inline-flex;
    padding: 0;
    margin: 0;
    align-items: center;
    list-style: none;

    &-wrapper {
        width: remToPx(45.1rem);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;


    }

    li {
        padding: 0;
        margin: 0;
        flex-grow: 1;
        text-decoration: none;
        color: #fff;

        a.adage-cpg-social {
            font-size: 12px;
            line-height: 12px;
            text-decoration: none;
            color: #fff;
            display: flex;
            align-items: center;
            background-image: none;
            padding-right: 0;
            font-weight: $font-weight-normal;

            svg {
                height: 18px;
                width: 18px;
                fill: #fff;
                margin-right: 5px;
            }

            img {
                height: 18px;
                width: 18px;
                fill: #fff;
                margin-right: 5px;
            }
        }

        + li {
            margin-left: 5px;
            padding-left: 5px;
        }
    }
}