﻿/// <reference path="../../entries/main">

.sitewide-notification {
    @extend %standard-padding-h;
    padding-top: $gap-sm;
    padding-bottom: $gap-sm;
    color: $white;

    svg {
        fill: $white
    }

    &-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-icon {
        svg {
            height: 1.25rem;
            width: 1.25rem;
            fill: $white;
        }

        margin-right: $gap-md;

        @include media(medium) {
            margin-right: $gap-sm;
        }
    }

    &-content {
        display: flex;
        margin-right: $gap-sm;

        &-inner {
            @include media-min(tablet) {
                display: flex;
                align-items: flex-start;
            }
        }

        &-header {
            font-size: 16px; /// interior override
            margin-right: $gap-sm;
            color: $white;
            margin-bottom: 0;

            @include media(tablet) {
                margin-bottom: $space;
            }
        }

        &-description {
            p {
                margin: 0;
            }
        }

        a {
            @include link-helper($white, $white);
            background-image: none !important;
            padding-right: 0 !important;
            text-decoration: underline;
        }
    }

    &-close {
        @extend %un-button;
        align-self: flex-start;

        svg {
            height: .75rem;
            width: .75rem;
        }
    }

    &-warning {
        background: $yellow;
        color: $black;

        svg {
            fill: $black;
        }

        a {
            @include link-helper($black, $black)
        }

        .sitewide-notification-content-header {
            color: $black; // override for interior pages
        }
    }

    &-urgent {
        background: $red;
    }

    &-info {
        background: $blue-dark;
    }

    &-positive {
        background: $green;
    }

    &[aria-hidden='true'] {
        display: none;
    }
}
