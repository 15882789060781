﻿/// <reference path="../../entries/main">

.password-input {
    $self: &;
    position: relative;

    &-toggle {
        display: block;
        position: absolute;
        right: $svg-icon-sm / 2;
        top: 50%;
        transform: translateY(-50%);
        width: $ui-icon-size;
        height: $ui-icon-size;
        background: no-repeat center center;
        cursor: pointer;

        @include icon-eye-shown($gray-dark);

        &[aria-pressed="true"] {
            @include icon-eye-hidden($gray-dark);
        }
    }

    input:not([type="checkbox"]):not([type="radio"]) {
        padding-right: $svg-icon-sm * 2;

        &[style*="background-image"] {
            // For inputs with lastpass styles attached, push the button over to the left.
            $_lastpass-icon-width: 16px;

            padding-right: calc(#{$svg-icon-sm * 2} + #{$gap-sm} + #{$_lastpass-icon-width});

            & + #{$self}-toggle {
                right: calc(#{$svg-icon-sm / 2} + #{$gap-sm} + #{$_lastpass-icon-width});
            }
        }
    }
}
