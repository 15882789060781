﻿/// <reference path="../entries/main">

%body {
    font-size: 100%;
    @include font-size($reg-font-sizes);
    font-family: $body-font, serif;
    font-weight: $font-weight-normal;
    color: $default-text;

    ::selection {
        background: $gray-dark;
        color: $white;
    }

    ::-moz-selection {
        background: $gray-dark;
        color: $white;
    }
}

%h1, %h2, %h3, %h4 {
    font-weight: $font-weight-light;
}

%h2, %h3, %h5 {
    font-family: $heading-font;
    font-weight: $font-weight-normal;
}

%h1 {
    @include font-size($x-large-font-sizes);
}

%h2 {

    @include font-size($large-font-sizes);
}

%h3 {
    @include font-size($medium-large-font-sizes);
}

%h4 {
    @include font-size($medium-font-sizes);
}

%h5 {
    @include font-size($reg-font-sizes);
}

%h6 {
    @include font-size($small-font-sizes);
}

%large {
    @include font-size($reg-font-sizes);
}

%small {
    @include font-size($xs-font-sizes);
}

%accent {
    @include font-size($label-font-sizes);
    font-family: $accent-font;
}

/*%hyperlink {
    text-decoration: underline;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}*/